import React, { useState, useEffect } from 'react';
import Markdown from 'markdown-to-jsx';
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  Button,
  Chip,
  Box,
  LinearProgress,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import DownloadIcon from '@mui/icons-material/Download';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { downloadPDF } from './downloadPdf';
import './styles.scss';
import { useNavigate } from 'react-router-dom';
import CustomSnackbar from '../customSnackbar';

const InstructionsComponent = (props) => {
  const navigate = useNavigate();
  const [expandedAccordion, setExpandedAccordion] = useState(null);
  const [expandedSubAccordion, setExpandedSubAccordion] = useState(null);
  const [instructionsData, setInstructionsData] = useState([]);
  const [countdown, setCountdown] = useState(60);
  const [showInstructions, setShowInstructions] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState('success');

  const tempPasscode = props?.tap?.tap;
  const registrationType = props?.registrationType;
  const selectedUpn = props?.selectedUpn;

  const RenderBoldText = ({ text }) => {
    return <Markdown>{text}</Markdown>;
  };

  useEffect(() => {
    const timer = setInterval(() => {
      setCountdown((prev) => {
        if (prev <= 1) {
          clearInterval(timer);
          setShowInstructions(true);
          return 0;
        }
        return prev - 1;
      });
    }, 1000);

    return () => clearInterval(timer);
  }, []);

  useEffect(() => {
    const loadInstructions = async () => {
      try {
        if (registrationType === 'SecurityKey') {
          const data = await import('./securityKeyInstructions.json');
          setInstructionsData(data.default);
        } else if (registrationType === 'Microsoft Authenticator') {
          const data = await import('./authenticatorInstructions.json');
          setInstructionsData(data.default);
        }
      } catch (error) {
        console.error('Error loading instructions data:', error);
      }
    };
    loadInstructions();
  }, [registrationType]);

  const handleAccordionChange = (panel) => (event, isExpanded) => {
    setExpandedAccordion(isExpanded ? panel : null);
  };

  const handleSubAccordionChange = (panel) => (event, isExpanded) => {
    setExpandedSubAccordion(isExpanded ? panel : null);
  };

  const handleSnackbarClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setSnackbarOpen(false);
  };

  const handleCopyAndContinue = () => {
    if (tempPasscode) {
      navigator.clipboard
        .writeText(tempPasscode)
        .then(() => {
          setSnackbarMessage('Passcode copied to clipboard');
          setSnackbarSeverity('success');
          setSnackbarOpen(true);
        })
        .catch((err) => {
          console.error('Failed to copy passcode: ', err);
          setSnackbarMessage('Failed to copy passcode');
          setSnackbarSeverity('error');
          setSnackbarOpen(true);
        });
      if (registrationType === 'SecurityKey') {
        const targetUrl = `https://mysignins.microsoft.com/security-info?tenant=${process.env.REACT_APP_TENANT_ID}&login_hint=${selectedUpn}`;
        window.open(targetUrl, '_blank');
      }
    }
  };

  const listItemStyle = { marginBottom: '10px' };

  return (
    <div className="instructions-container">
      {/* Dynamic Header */}
      <div className="instructions-header">
        <Typography variant="h5" sx={{ fontWeight: 'bold', color: '#e20074' }}>
          {registrationType === 'SecurityKey'
            ? 'Step1: Register Security Key and Microsoft Authenticator'
            : 'Step2: Enable/Set up phone sign-in'}
        </Typography>
      </div>
      {!showInstructions ? (
        // Timer Bar
        <div className="timer-bar">
          <Typography
            variant="h6"
            sx={{ textAlign: 'center', marginBottom: '10px' }}
          >
            Activating
          </Typography>
          <Typography
            variant="body2"
            sx={{ textAlign: 'center', marginTop: '10px' }}
          >
            {`${countdown} second(s) remaining`}
          </Typography>
          <LinearProgress
            variant="determinate"
            value={(countdown / 60) * 100}
            sx={{
              height: 10,
              backgroundColor: '#f0f0f0',
              '& .MuiLinearProgress-bar': {
                backgroundColor: '#e20074',
                transform: 'scaleX(-1)',
                transformOrigin: 'right',
              },
            }}
          />
        </div>
      ) : (
        <>
          {/* Sticky Passcode Section */}
          <div className="sticky-passcode">
          <Typography
                variant="h6"
                component="span"
                className="temp-passcode"
              >
                {`Email: ${selectedUpn}`}
              </Typography>
            <Box
              display="flex"
              alignItems="center"
              justifyContent="center"
              flexWrap="wrap"
              gap={2}
            >
              <Typography
                variant="h6"
                component="span"
                className="temp-passcode"
              >
                               Temporary Access Passcode:
                <Chip
                  label={tempPasscode}
                  sx={{
                    marginLeft: '10px',
                    backgroundColor: '#fffbcc',
                    color: '#e20074',
                    fontWeight: 'bold',
                    fontSize: '1.25rem',
                  }}
                />
              </Typography>
              {registrationType && (
                <Button
                  variant="contained"
                  color="primary"
                  startIcon={<ContentCopyIcon />}
                  onClick={handleCopyAndContinue}
                  className="commonButton"
                >
                  {registrationType === 'SecurityKey'
                    ? 'Copy and Continue'
                    : 'Copy Passcode'}
                </Button>
              )}
            </Box>
            <Typography
              variant="body2"
              color="textSecondary"
              style={{ marginTop: '10px', textAlign: 'center' }}
            >
              <strong>Note:</strong> This token is valid for{' '}
              {props?.tap?.lifetimeInMinutes} minutes. If you refresh the page
              or lose the token, you will need to restart the process to
              generate a temporary passcode.
            </Typography>
          </div>

          {/* Download PDF Button */}
          <div className="download-section">
            <Button
              variant="contained"
              color="primary"
              onClick={() =>
                downloadPDF(
                  instructionsData,
                  `${registrationType}_Instructions.pdf`
                )
              }
              startIcon={<DownloadIcon />}
              className="commonButton"
            >
              Download Instructions (PDF)
            </Button>
          </div>

          {/* Accordion Instructions */}
          <div style={{ marginTop: '20px' }}>
            {instructionsData.map((section, index) => (
              <Accordion
                key={`section-${index}`}
                expanded={expandedAccordion === `panel${index}`}
                onChange={handleAccordionChange(`panel${index}`)}
                sx={{ marginTop: '20px' }}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  sx={{ backgroundColor: '#e20074', color: 'white' }}
                >
                  <Typography>
                    <RenderBoldText text={section.section} />
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography component="div">
                    {section?.steps && (
                      <ol style={{ textAlign: 'left' }}>
                        {section.steps.map((step, stepIndex) => (
                          <li key={`step-${stepIndex}`} style={listItemStyle}>
                            <RenderBoldText text={step} />
                          </li>
                        ))}
                      </ol>
                    )}
                    {section?.subSections &&
                      section?.subSections.map((subSection, subIndex) => (
                        <Accordion
                          key={`subSection-${index}-${subIndex}`}
                          expanded={
                            expandedSubAccordion ===
                            `subPanel${index}-${subIndex}`
                          }
                          onChange={handleSubAccordionChange(
                            `subPanel${index}-${subIndex}`
                          )}
                          sx={{ marginTop: '10px', marginLeft: '10px' }}
                        >
                          <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            sx={{ backgroundColor: '#e20074', color: 'white' }}
                          >
                            <Typography>
                              <RenderBoldText text={subSection.subSection} />
                            </Typography>
                          </AccordionSummary>
                          <AccordionDetails>
                            <Typography component="div">
                              <ol style={{ textAlign: 'left' }}>
                                {subSection.steps.map((step, subStepIndex) => (
                                  <li
                                    key={`subStep-${index}-${subIndex}-${subStepIndex}`}
                                    style={listItemStyle}
                                  >
                                    <RenderBoldText text={step} />
                                  </li>
                                ))}
                              </ol>
                            </Typography>
                          </AccordionDetails>
                        </Accordion>
                      ))}
                  </Typography>
                </AccordionDetails>
              </Accordion>
            ))}
          </div>

          {/* Sticky Return to Home Button */}
          <div
            style={{
              position: 'sticky',
              bottom: 0,
              backgroundColor: '#fff',
              padding: '10px',
              boxShadow: '0 -2px 4px rgba(0, 0, 0, 0.1)',
            }}
          >
            <Button
              onClick={() => navigate('/')}
              variant="contained"
              color="primary"
              className="commonButton"
              // sx={{
              //   boxSizing: 'border-box',
              //   padding: '12px 36px',
              //   fontStyle: 'normal',
              //   fontSize: '14px',
              //   fontWeight: 'bold',
              //   color: '#ffffff',
              //   lineHeight: '16px',
              //   backgroundColor: '#e20074',
              //   border: '1px solid #e20074',
              //   borderRadius: '5px',
              //   cursor: 'pointer',
              //   textTransform: 'none',
              //   width: 'auto',
              //   '&:hover': {
              //     backgroundColor: '#cc0066',
              //   },
              // }}
            >
              Return to Home
            </Button>
          </div>
          <CustomSnackbar
            open={snackbarOpen}
            autoHideDuration={10000}
            onClose={handleSnackbarClose}
            message={snackbarMessage}
            severity={snackbarSeverity}
          />
        </>
      )}
    </div>
  );
};

export default InstructionsComponent;
