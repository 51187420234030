import axios from 'axios';
import poptoken_builder from '../popToken/poptoken-builder-node';
import { isTokenExpired, refreshGuestToken } from '../../hooks/useToken';

export const axiosAzure = async ({
  method = 'get',
  url,
  data,
  otherHeaders = null,
}) => {
  if (!url || typeof url !== 'string') {
    throw new Error('Invalid URL: URL must be a non-empty string');
  }

  // Validate the URL to prevent SSRF attacks
  const isValidUrl = validateUrl(url);
  if (!isValidUrl) {
    throw new Error('Invalid URL: SSRF protection - URL not allowed');
  }

  let idToken = sessionStorage.getItem('idToken');
  const isTokenExp = await isTokenExpired(idToken);

  // Verify if the token is expired and refresh if necessary
  if (!idToken || isTokenExp) {
    console.log('Token expired or missing, refreshing...');
    idToken = await refreshGuestToken();
  }

  const pop_token = createPopToken(method, url, `Bearer ${idToken}`, data);
  console.log('isTokenExp', isTokenExp);

  // List of endpoints where 'mmfa-token' should be excluded
  const excludeMmfaToken = [
    process.env.REACT_APP_GUEST_TOKEN_URL,
    `${process.env.REACT_APP_API_BASE_URL_MEG}/session/create`,
  ];
  const shouldExcludeMmfaToken = excludeMmfaToken.some((apiUrl) =>
    url.startsWith(apiUrl)
  );

  const headers = {
    ...otherHeaders,
    Authorization: `Bearer ${idToken}`,
    'X-Auth-Originator': idToken,
    'X-Authorization': pop_token,
    ...(shouldExcludeMmfaToken
      ? {}
      : { 'mmfa-token': sessionStorage.getItem('sessionToken') }),
  };

  const sessionToken = sessionStorage.getItem('sessionToken');
  if (sessionToken) {
    headers['mmfa-token'] = sessionToken;
  }

  return axios({
    method,
    url,
    data,
    headers,
  });
};

export const createPopToken = (method, url, authorization, body) => {
  const ehtsKeyValueMap = new Map();
  const pathname = new URL(url).pathname;
  // ehtsKeyValueMap.set('Authorization', authorization);
  // ehtsKeyValueMap.set('uri', pathname);
  ehtsKeyValueMap.set('http-method', method.toUpperCase());
  // if (!(method === 'get' || JSON.stringify(body) === '{}')) {
  //   ehtsKeyValueMap.set('body', method === 'get' ? '' : JSON.stringify(body));
  // }

  const privateKeyPemStr = '' + process.env.REACT_APP_PRIVATE_KEY_MEG;
  try {
    const popToken = poptoken_builder.buildPopToken(
      ehtsKeyValueMap,
      privateKeyPemStr
    );
    return popToken;
  } catch (error) {
    console.log('Error:', error);
  }
};

// Helper function to validate URLs
const validateUrl = (url) => {
  try {
    const parsedUrl = new URL(url);
    // Ensure the URL is using HTTP or HTTPS protocols
    if (parsedUrl.protocol !== 'http:' && parsedUrl.protocol !== 'https:') {
      console.error('Invalid protocol:', parsedUrl.protocol);
      return false;
    }

    // Disallow URLs pointing to internal IP ranges
    const hostname = parsedUrl.hostname;
    if (isInternalIp(hostname)) {
      console.error('Internal IP not allowed:', hostname);
      return false;
    }

    return true;
  } catch (error) {
    console.error('Invalid URL format:', error);
    return false;
  }
};

// Helper function to check if the IP is internal
const isInternalIp = (hostname) => {
  // Regex for IPv4 and IPv6 private addresses and localhost
  const privateIpRegex =
    /^(localhost|127\.0\.0\.1|::1|10\.\d{1,3}\.\d{1,3}\.\d{1,3}|172\.(1[6-9]|2\d|3[01])\.\d{1,3}\.\d{1,3}|192\.168\.\d{1,3}\.\d{1,3}|fc00::|fe80::)/;

  return privateIpRegex.test(hostname);
};
