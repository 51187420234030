import React from 'react';
import VerifyMe from '../verifyMe';
import MFARegistration from '../mfaRegistration';
import LoginCallback from '../welcome/loginRedirection';
import ErrorPage from './errorComponent/commonErrorLayout';

const routes = [
  {
    path: '/',
    element: <VerifyMe />,
    exact: true,
  },
  {
    path: '/:sessionToken', // Match any UUID pattern in the URL
    element: <MFARegistration />,
    exact: true,
  },
  {
    path: '/unauthorized',
    element: <ErrorPage type="unauthorized" />,
  },
  {
    path: '/server-error',
    element: <ErrorPage type="serverError" />,
  },
  {
    path: '/page-not-found',
    element: <ErrorPage type="pageNotFound" />,
  },

  {
    path: '/forbidden',
    element: <ErrorPage type="pageNotFound-Forbidden" />,
  },
  {
    path: '/unknown-error',
    element: <ErrorPage type="unknownError" />,
  },
  {
    path: '/success',
    element: <ErrorPage mode="success" type="success" />,
  },
];

export default routes;
