import { axiosAzure } from '../interceptors/axios-azure';

const { REACT_APP_API_BASE_URL_MEG, REACT_APP_GUEST_TOKEN_URL } = process.env;

export const httpFetchGuestToken = data => {
  const url = `${REACT_APP_GUEST_TOKEN_URL}`;
  return axiosAzure({ 
    method: 'post', 
    url, 
    data 
  });
};

export const httpPostCreateSession = data => {
  const url = `${REACT_APP_API_BASE_URL_MEG}/session/create`;
  return axiosAzure({ 
    method: 'post', 
    url, 
    data 
  });
};

export const httpGetSession = (sessionToken) => {
  const url = `${REACT_APP_API_BASE_URL_MEG}/session/${sessionToken}`;
  return axiosAzure({ 
    method: 'get', 
    url
  });
};

export const httpGetRetriveTap = (userId) => {
  const url = `${REACT_APP_API_BASE_URL_MEG}/register/${userId}/retrieve-tap`;
  return axiosAzure({ 
    method: 'get', 
    url 
  });
};

export const httpGetCreateOptions = (userId) => {
  const url = `${REACT_APP_API_BASE_URL_MEG}/register/${userId}/create-options`;
  return axiosAzure({ 
    method: 'get', 
    url 
  });
};


export const httpRegisterPassKey = (data, userId) => {
  const url = `${REACT_APP_API_BASE_URL_MEG}/register/${userId}/add-security-key`;
  console.log("data", data);
  return axiosAzure({ 
    method: 'post', 
    url, 
    data 
  });
};
