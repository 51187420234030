/**
  tmo-poptoken-builder
  @module: js poptoken Builder
  @description: Defines functions for the pop token JavaScript Utility
  @author: abhardw16
  @version: 1.0
**/
const rs = require("jsrsasign");
const { v4: uuidv4 } = require('uuid');

const poptoken_builder = {
  buildPopToken: function (ehtsKeyValuMap, privateKeyPemString) {
    /*
     param ehtsKeyValuMap - Map to be signed
     param privateKeyPemString - Private Key PEM string
    */
    const objClaim = {};
    let strEhts = "";
    let strToHash = "";
    let strToken = "";
    const curTime = "now";
    const currentTimeInSeconds = Math.floor(new Date().getTime() / 1000);
    const expTime = currentTimeInSeconds + 2 * 60; // token is valid for 2 minutes

    for (const [key, value] of ehtsKeyValuMap) {
      if (strEhts.length > 0) {
        strEhts = strEhts + ";";
      }
      strEhts = strEhts + key;
      strToHash = strToHash + value;
    }

    const strHash = rs.KJUR.crypto.Util.sha256(strToHash);
    const strEdtsHashB64U = rs.hextob64u(strHash);

    const strUniq = poptoken_builder.createGUID();

    if (strEdtsHashB64U.length > 0) {
      objClaim.edts = strEdtsHashB64U;
    }

    objClaim.v = "1";

    if (expTime !== "") {
      objClaim.exp = expTime;
    }

    if (strEhts.length > 0) {
      objClaim.ehts = strEhts;
    }

    if (curTime !== "") {
      objClaim.iat = rs.KJUR.jws.IntDate.get(curTime);
    }

    if (strUniq.length > 0) {
      objClaim.jti = strUniq;
    }

    const sClaim = JSON.stringify(objClaim);
    const alg = "RS256";
    const pHeader = { alg: alg, typ: "JWT" };
    const sHeader = JSON.stringify(pHeader);

    strToken = rs.KJUR.jws.JWS.sign(null, sHeader, sClaim, privateKeyPemString);

    return strToken;
  },

  buildPopTokenWithPassword: function (
    ehtsKeyValuMap,
    encryptedPrivateKeyPemString,
    password
  ) {
    /*
     param ehtsKeyValuMap - Map to be signed
     param privateKeyPemString - Private Key PEM string
     param password - Private Key password
    */
    const objClaim = {};
    let strEhts = "";
    let strToHash = "";
    let strToken = "";
    const curTime = "now";
    const currentTimeInSeconds = Math.floor(new Date().getTime() / 1000);
    const expTime = currentTimeInSeconds + 2 * 60; // token is valid for 2 minutes

    for (const [key, value] of ehtsKeyValuMap) {
      if (strEhts.length > 0) {
        strEhts = strEhts + ";";
      }
      strEhts = strEhts + key;
      strToHash = strToHash + value;
    }

    const strHash = rs.KJUR.crypto.Util.sha256(strToHash);
    const strEdtsHashB64U = rs.hextob64u(strHash);

    const strUniq = poptoken_builder.createGUID();

    if (strEdtsHashB64U.length > 0) {
      objClaim.edts = strEdtsHashB64U;
    }

    objClaim.v = "1";

    if (expTime !== "") {
      objClaim.exp = expTime;
    }

    if (strEhts.length > 0) {
      objClaim.ehts = strEhts;
    }

    if (curTime !== "") {
      objClaim.iat = rs.KJUR.jws.IntDate.get(curTime);
    }

    if (strUniq.length > 0) {
      objClaim.jti = strUniq;
    } 

    const sClaim = JSON.stringify(objClaim);
    const alg = "RS256";
    const pHeader = { alg: alg, typ: "JWT" };
    const sHeader = JSON.stringify(pHeader);

    const keyObj = rs.KEYUTIL.getKey(encryptedPrivateKeyPemString, password);

    strToken = rs.KJUR.jws.JWS.sign(null, sHeader, sClaim, keyObj);

    return strToken;
  }, 

  createGUID: function () {
    function randomStr() {
      return Math.floor((1 + uuidv4()) * 0x10000)
      .toString(16)
      .substring(1);
    }
    return (
      randomStr() +
      randomStr() +
      "-" +
      randomStr() +
      "-" +
      randomStr() +
      "-" +
      randomStr() +
      "-" +
      randomStr() +
      randomStr() +
      randomStr()
    );
  },
};

export default poptoken_builder;
