import { jwtDecode } from 'jwt-decode';
import { httpFetchGuestToken } from '../api/REST_services_meg/mfaRegistration';

let isRefreshing = false;
let refreshPromise = null;

export const refreshGuestToken = async () => {
  if (isRefreshing) {
    console.warn(
      'Token refresh already in progress, returning ongoing promise'
    );
    return refreshPromise;
  }
  try {
    isRefreshing = true;
    refreshPromise = await httpFetchGuestToken({
      client_id: process.env.REACT_APP_CLIENTID,
      cnf: process.env.REACT_APP_PUBLIC_KEY,
    });

    const response = await refreshPromise;

    if (!response.data || !response.data.id_token) {
      throw new Error('Failed to refresh guest token');
    }

    const { id_token, access_token } = response.data;

    // Store the refreshed tokens in sessionStorage
    sessionStorage.setItem('idToken', id_token);
    sessionStorage.setItem('accessToken', access_token);

    return id_token;
  } catch (error) {
    console.error('Error refreshing guest token:', error);
    throw error;
  } finally {
    isRefreshing = false;
    refreshPromise = null;
  }
};

export const isTokenExpired = async(token) => {
  try {
    const decoded = jwtDecode(token);
    const currentTime = Math.floor(Date.now() / 1000);
    return decoded.exp < currentTime;
  } catch (error) {
    console.error('Error decoding token:', error);
    return true;
  }
};