export default {
  unauthorized: {
    heading: 'Unauthorized Access',
    description: 'You do not have permission to access this page.',
    primaryButtonText: 'Return to Home Page',
  },
  pageNotFound: {
    heading: '404 Page not found',
    description: 'The page you are looking for does not exist.',
    primaryButtonText: 'Return to Home Page',
  },
  'pageNotFound-Forbidden': {
    heading: 'Unauthorized access',
    description: 'You do not have access to this resource.',
    primaryButtonText: 'Return to Home Page',
    secondaryButtonText: 'Return to Home Page',
  },
  serverError: {
    heading: 'INTERNAL SERVER ERROR',
    description:
      'An unexpected error occurred. Please try again later or contact support if the issue persists.',
    primaryButtonText: 'Return to Home Page',
    secondaryButtonText: 'Return to Home Page',
  },
  unknownError: {
    heading: 'Unknown Error',
    description: 'An unexpected error occurred. Please try again.',
    primaryButtonText: 'Return to Home Page',
  },
  success: {
    heading: 'Success!',
    description: 'Security key registered successfully!',
    primaryButtonText: 'Return to Home Page',
  },
};
