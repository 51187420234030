import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import mainContainerClasses from '../mainContainer/MainContainer.module.scss';
import TMOLogo from '../../assets/Icons_Illustrations/Universal_Logos_Icons/tmo_logo_v2.svg';

const Header = () => {
  const navigate = useNavigate();
  const pathname = useLocation().pathname;

  // Handle logo click navigation
  const handleLogoClick = () => {
    navigate('/');
  };

  return (
    <header className={mainContainerClasses.headerStyle}>
      <div className={mainContainerClasses.headerLayout}>
        <span className={mainContainerClasses.headerText} onClick={() => pathname !== '/' && handleLogoClick()}>
          <img src={TMOLogo} alt="logo" />
          <span className={mainContainerClasses.headerTitle}>Magenta MFA Registration</span>
        </span>
      </div>
    </header>
  );
};

export default Header;
