import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Container,
  Radio,
  RadioGroup,
  FormControl,
  FormControlLabel,
  FormLabel,
  Button,
  Box,
} from '@mui/material';
import './styles.scss';
import {
  httpGetSession,
  httpGetRetriveTap,
} from '../../api/REST_services_meg/mfaRegistration';
import { useParams } from 'react-router-dom';
import InstructionsComponent from '../tapInstructions/index';
import CustomSnackbar from '../customSnackbar';
import Loader from '../mainContainer/loader';

const getBackgroundImage = () => {
  return 'onload-bg-img';
};

const MFARegistration = () => {
  const navigate = useNavigate();
  const { sessionToken } = useParams();
  useEffect(() => {
    if (sessionToken) {
      /* istanbul ignore next */
      sessionStorage.setItem('sessionToken', sessionToken);
    }
  }, [sessionToken]);
  const formLabelStyles = {
    fontFamily: 'TeleGroteskNextRegular',
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: '18px',
    lineHeight: '21px',
    color: '#000000',
    textAlign: 'left',
    marginBottom: '8px',
  };
  // Define user state and loading state
  const [loading, setLoading] = useState(false);
  const [backgroundImage, setBackgroundImage] = useState(getBackgroundImage());
  const [accountList, setAcountList] = useState([]);
  const [tap, setTap] = useState(null);
  const [registrationType, setRegistrationType] = useState('');
  const [accountType, setAccountType] = useState('');
  const [showRegistrationType, setShowRegistrationType] = useState(false);
  const [selectedUpn, setSelectedUpn] = useState('');
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState('success');

  useEffect(() => {
    apiCall();
  }, []);

  const apiCall = async () => {
    try {
      setLoading(true);

      const response = await httpGetSession(sessionToken);
      const accountList = response?.data || [];
      // const accountList = [
      //   {
      //     'userId': '3232deded32',
      //     upn: 'test@t-mobile.com',
      //   },
      //   {
      //     'userId': '3232deded323',
      //     upn: 'test@adm-t-mobile.com',
      //   },
      //   {
      //     'userId': '3232deded342',
      //     upn: 'test@ext-t-mobile.com',
      //   },
      // ];
      setAcountList(accountList);
    } catch (error) {
      console.error('httpGetSession submission error:', error);
      if (error?.response?.status === 400) {
        navigate('/');
      } else {
        handleError(error);
      }
    } finally {
      setLoading(false);
    }
  };
  /* istanbul ignore next */
  const handleSnackbarClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setSnackbarOpen(false);
  };

  const retriveTapApiCall = async (userId) => {
    try {
      setLoading(true);
      const response = await httpGetRetriveTap(userId);
      setTap(response?.data);
    } catch (error) {
      console.error('retriveTapApiCall error:', error);
      handleError(error);
    } finally {
      setLoading(false);
    }
  };

  const handleAccountChange = (event) => {
    const selectedUserId = event.target.value;
    setAccountType(selectedUserId);

    const selectedAccount = accountList.find(
      (account) => account.userId === selectedUserId
    );
    setSelectedUpn(selectedAccount?.upn || '');

    setShowRegistrationType(true);
    setRegistrationType('');
  };

  const handleRegistrationChange = (event) => {
    setRegistrationType(event.target.value);
  };

  const handleError = (error) => {
    const statusCode = error?.response?.status;

    if (statusCode === 401) {
      navigate('/unauthorized');
    } else if (statusCode === 403) {
      navigate('/forbidden');
    } else if (statusCode === 404) {
      navigate('/page-not-found');
    } else if (statusCode >= 500) {
      navigate('/server-error');
    } else {
      /* istanbul ignore next */
      navigate('/unknown-error');
    }
  };

  const handleSubmit = () => {
    // Handle the selected registration and account type
    console.log('Selected Registration:', registrationType);
    console.log('Selected Account Type:', accountType);
    retriveTapApiCall(accountType);
  };

  return (
    <div className={`container identity-main ${backgroundImage}`}>
      {loading ? (
        <div className="loader-overlay">
          <Loader />
        </div>
      ) : tap ? (
        <div className="white-box" style={{ maxWidth: '900px' }}>
          <InstructionsComponent
            tap={tap}
            registrationType={registrationType}
            selectedUpn={selectedUpn}
          />
        </div>
      ) : (
        <div className="white-box">
          <Container maxWidth="md" className="formContainer">
            {/* Account Type Selection */}
            <Box mt={3}>
              <FormControl component="fieldset" fullWidth>
                <FormLabel component="legend" style={formLabelStyles}>
                  Select Account Type
                </FormLabel>
                <RadioGroup
                  aria-label="account-type"
                  name="accountType"
                  value={accountType}
                  onChange={handleAccountChange}
                >
                  {accountList.map((item) => (
                    <FormControlLabel
                      key={item['userId']}
                      value={item['userId']}
                      control={<Radio className="radio-btn" />}
                      label={`${item?.upn}`}
                    />
                  ))}
                </RadioGroup>
              </FormControl>
            </Box>

            {/* Registration Type Selection */}
            {showRegistrationType && (
              <Box mt={3}>
                <FormControl component="fieldset" fullWidth>
                  <FormLabel component="legend" style={formLabelStyles}>
                    Follow the steps to complete registration:
                  </FormLabel>
                  <RadioGroup
                    aria-label="registration-type"
                    name="registrationType"
                    value={registrationType}
                    onChange={handleRegistrationChange}
                  >
                    <FormControlLabel
                      value="SecurityKey"
                      control={<Radio className="radio-btn" />}
                      label="Step1: Register Security Key and Microsoft Authenticator"
                    />
                    <FormControlLabel
                      value="Microsoft Authenticator"
                      control={<Radio className="radio-btn" />}
                      label="Step2: Enable/Set up phone sign-in"
                    />
                  </RadioGroup>
                </FormControl>
              </Box>
            )}

            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                mt: 2,
              }}
            >
              <Button
                onClick={handleSubmit}
                variant="contained"
                color="primary"
                disabled={!registrationType}
                // className="submitButton"
                sx={{
                  boxSizing: 'border-box',
                  padding: '12px 36px',
                  fontStyle: 'normal',
                  fontSize: '14px',
                  fontWeight: 'bold',
                  color: '#ffffff',
                  lineHeight: '16px',
                  backgroundColor: '#e20074',
                  border: '1px solid #e20074',
                  borderRadius: '5px',
                  cursor: 'pointer',
                  textTransform: 'none',
                  width: 'auto',
                  '&:hover': {
                    backgroundColor: '#cc0066',
                  },
                  '&:disabled': {
                    backgroundColor: '#f0b1ca',
                    color: 'rgba(255, 255, 255, 0.6)',
                  },
                }}
              >
                Submit
              </Button>
            </Box>
          </Container>
          <CustomSnackbar
            open={snackbarOpen}
            autoHideDuration={10000}
            onClose={handleSnackbarClose}
            message={snackbarMessage}
            severity={snackbarSeverity}
          />
        </div>
      )}
    </div>
  );
};

export default MFARegistration;
