import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import './styles.scss';
import verifyImage from '../../assets/image.svg';
import {
  httpFetchGuestToken,
  httpPostCreateSession,
} from '../../api/REST_services_meg/mfaRegistration';
import Loader from '../mainContainer/loader';

const getBackgroundImage = () => {
  return 'onload-bg-img';
};

const verifyMe = () => {
  const navigate = useNavigate();

  // Define user state and loading state
  const [loading, setLoading] = useState(false);
  const [backgroundImage, setBackgroundImage] = useState(getBackgroundImage());

  useEffect(() => {
    sessionStorage.removeItem('sessionToken');
    const fetchGuestToken = async () => {
      try {
        setLoading(true);
        const response = await httpFetchGuestToken({
          client_id: process.env.REACT_APP_CLIENTID,
          cnf: process.env.REACT_APP_PUBLIC_KEY,
        });

      //   const response = {
      //   data: {
      //     id_token:
      //       '',
      //     access_token:
      //       '',
      //   },
      // };
      /* istanbul ignore next */ 
        console.log('httpFetchGuestToken response', response);
        const { id_token, access_token } = response.data;
        sessionStorage.setItem('idToken', id_token);
        sessionStorage.setItem('accessToken', access_token);
      } catch (error) {
        console.error('httpFetchGuestToken error:', error);
        handleError(error);
      } finally {
        setLoading(false);
      }
    };
  
    fetchGuestToken();
  }, []);

  const handleError = (error) => {
    const statusCode = error?.response?.status;

    if (statusCode === 401) {
      navigate('/unauthorized');
    } else if (statusCode === 403) {
      navigate('/forbidden');
    } else if (statusCode === 404) {
      navigate('/page-not-found');
    } else if (statusCode >= 500) {
      navigate('/server-error');
    } else {
      navigate('/unknown-error');
    }
  };

  const onVerifyMeHandler = async () => {
    try {
      setLoading(true);

      const response = await httpPostCreateSession({});
      console.log('httpPostCreateSession response', response);

      const clearRedirectUrl = response?.data?.endpoint;
      // const clearRedirectUrl =
      //   'https://verified.clearme.com/verify?token=verify_token_123';
      // const url = new URL(clearRedirectUrl);
      // const token = url.searchParams.get('token');
      // sessionStorage.setItem('verify_token', token);
      if (clearRedirectUrl) {
        /* istanbul ignore next */
        window.location.href = clearRedirectUrl;
      }
    } catch (error) {
      console.error('httpPostCreateSession submission error:', error);
      handleError(error);
    } finally {
      setLoading(false);
    }
  };

  // Conditionally render loader or main content based on the loading state
  return (
    <div
      data-testid="identity-container"
      className={`container identity-main ${backgroundImage}`}
    >
      {loading ? (
        <div className="loader-overlay">
          <Loader />
        </div>
      ) : (
        <div className="white-box">
          <button
            className="verify-button"
            id="verify_button_d"
            type="button"
            onClick={onVerifyMeHandler}
            disabled={loading}
          >
            <img src={verifyImage} alt="Verify Me" />
            Verify Me
          </button>
        </div>
      )}
    </div>
  );
};

export default verifyMe;
