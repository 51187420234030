import jsPDF from 'jspdf';

export const downloadPDF = (instructionsData, fileName = 'MFA_instructions.pdf') => {
  const doc = new jsPDF();
  const pageHeight = doc.internal.pageSize.height;
  const pageWidth = doc.internal.pageSize.width;
  const margin = 10; 
  const textWidth = pageWidth - 2 * margin;
  const lineHeight = 8;
  let yOffset = 10;

  instructionsData.forEach((section) => {
    doc.setFontSize(16);
    const sectionText = doc.splitTextToSize(section.section, textWidth);

    if (yOffset + sectionText.length * lineHeight > pageHeight) {
      doc.addPage();
      yOffset = 10;
    }
    doc.text(sectionText, margin, yOffset);
    yOffset += sectionText.length * lineHeight;

    if (section.steps) {
      section.steps.forEach((step, index) => {
        doc.setFontSize(12);
        const stepText = doc.splitTextToSize(`${index + 1}. ${step}`, textWidth);

        if (yOffset + stepText.length * lineHeight > pageHeight) {
          doc.addPage();
          yOffset = 10;
        }
        doc.text(stepText, margin, yOffset);
        yOffset += stepText.length * lineHeight;
      });
    }

    if (section.subSections) {
      section.subSections.forEach((subSection) => {
        doc.setFontSize(14);
        const subSectionText = doc.splitTextToSize(`- ${subSection.subSection}`, textWidth);

        if (yOffset + subSectionText.length * lineHeight > pageHeight) {
          doc.addPage();
          yOffset = 10;
        }
        doc.text(subSectionText, margin, yOffset);
        yOffset += subSectionText.length * lineHeight;

        subSection.steps.forEach((step, index) => {
          doc.setFontSize(12);
          const subStepText = doc.splitTextToSize(`   ${index + 1}. ${step}`, textWidth);

          if (yOffset + subStepText.length * lineHeight > pageHeight) {
            doc.addPage();
            yOffset = 10;
          }
          doc.text(subStepText, margin, yOffset);
          yOffset += subStepText.length * lineHeight;
        });
      });
    }

    yOffset += lineHeight;
  });

  doc.save(fileName);
};
