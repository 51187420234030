import './App.scss';
import MainContainer from './components/mainContainer/MainContainer';
import React from 'react';
import { createTheme, ThemeProvider } from '@mui/material/styles';

const App = ({}) => {
  const theme = createTheme({
    palette: {
      secondary: {
        main: '#e20074',
        contrastText: 'black',
      },
    },
  });
  return (
    <div data-testid="App">
      <ThemeProvider theme={theme}>
        <MainContainer />
      </ThemeProvider>
    </div>
  );
};
export default App;
