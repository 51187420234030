import React, { useEffect } from 'react';
import classes from './errorComponent.module.scss';
import { useNavigate } from 'react-router-dom';
import content from './errorContent';

const ErrorPage = (props) => {
  const { type, mode = 'error' } = props;
  const navigate = useNavigate();
  const errorContent = content[type] || content['unauthorized']; // default it to unauthorized
  
  useEffect(() => {
    sessionStorage.removeItem('sessionToken');
  }, []);

  const handleRedirectionOnButtonClick = ()=>{
    switch(type) {
      case 'unauthorized':
      case 'pageNotFound':
      case 'serverError':
      case 'pageNotFound-Forbidden':
      case 'unknownError':
      case 'success':
        return returnToHome();
      default: 
        return returnToHome();
    }
  }
  
  const returnToHome = () => {
    navigate('/');
  };

  
  return (
    <div className={classes.container}>
      <div className={classes.textContainer}>
      <span className={classes.errorHeading}>
      {/* <span className={mode === 'error' ? classes.errorHeading : classes.successHeading}> */}
          {errorContent['heading']}
        </span>
        <span className={classes.errorText}>
          {errorContent['description']}
        </span>
        <button className={classes.primaryButton} onClick={handleRedirectionOnButtonClick}>
        {errorContent['primaryButtonText']}
        </button>
      </div>
    </div>
   
  )
}

export default ErrorPage;