import React, { useEffect } from 'react';
import { Route, Routes } from 'react-router-dom';
import { onRemoveLogs } from '../../Utils/Utils';
import Header from '../common/Header';
import routes from '../common/routes';
import ErrorPage from '../common/errorComponent/commonErrorLayout';

const MainContainer = () => {

  onRemoveLogs();
  return (
    <>
      <Header />
      <Routes>
        {routes.map((item, i) => (
          <Route path={item.path} element={item.element} key={i} />
        ))}
        <Route path="*" element={<ErrorPage type="pageNotFound" />} />
      </Routes>
    </>
  );
};

export default MainContainer;
